

















































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Watch } from 'vue-property-decorator';
import {
  BusinessBreadcrumbs,
  BusinessTitleSubtitleBanner,
  Crumb,
  IconHolder,
  SectionHeader
} from '../../../components/widgets/index';
import {
  BusinessServices,
  ContactUsBusiness,
  FooterBlurb
} from '../../../components/content/index';
import {
  BusinessContactForm,
  BusinessContactSideForm
} from '../../../components/forms/index';
import buildMeta from '../../../libraries/meta';
import { Mutation } from 'vuex-class';

@Component({
  components: {
    BusinessBreadcrumbs,
    BusinessContactForm,
    BusinessContactSideForm,
    BusinessServices,
    BusinessTitleSubtitleBanner,
    ContactUsBusiness,
    FooterBlurb,
    IconHolder,
    SectionHeader
  }
})
export default class WesleyCloverPage extends Vue {
  product: String = '';
  crumbs: Array<Crumb> = [];
  mounted() {
    this.crumbs = [
      new Crumb(this.$t('bw.breadcrumbs:home'), '/business/'),
      new Crumb(
        this.$t('bw.breadcrumbs:business-phone'),
        '/business/business-voice-and-collaboration'
      ),
      new Crumb(
        this.$t('bw.breadcrumbs:wesley-clover'),
        '/business/business-voice-and-collaboration/trading-floor'
      )
    ];
  }
  @Watch('$i18n.locale')
  onLangChange() {
    this.crumbs = [
      new Crumb(this.$t('bw.breadcrumbs:home'), '/business/'),
      new Crumb(
        this.$t('bw.breadcrumbs:business-phone'),
        '/business/business-voice-and-collaboration'
      ),
      new Crumb(
        this.$t('bw.breadcrumbs:wesley-clover'),
        '/business/business-voice-and-collaboration/trading-floor'
      )
    ];
  }
  pdfImgClass() {
    return {
      'turret-devices-column hard-turret-img-en': this.$i18n.locale == 'en',
      'turret-devices-column hard-turret-img-fr': this.$i18n.locale == 'fr'
    };
  }
  softPdfClass() {
    return {
      'turret-devices-column soft-turret-img-en': this.$i18n.locale == 'en',
      'turret-devices-column soft-turret-img-fr': this.$i18n.locale == 'fr'
    };
  }
  head() {
    return buildMeta({
      title: this.$t('bw.wesley-clover:meta-title'),
      description: this.$t('bw.wesley-clover:meta-description'),
      canonical:
        'https://www.beanfield.com/business/business-voice-and-collaboration/trading-floor',
      socialImage: 'trading-floor',
      imageType: 'image/jpeg'
    });
  }

  layout() {
    return 'business';
  }

  @Mutation('businessForm/setSideFormState')
  setSideFormState;
  setProduct(product) {
    this.product = product;
    this.setSideFormState(true);
    this['$ga'].event({
      eventCategory: 'Get in Touch',
      eventAction: this.product,
      eventLabel: this['$route'].path
    });
  }

  fetch({ redirect }) {
    if (process.env.FEATURE_FLAG_TRADING_FLOOR === 'false') {
      redirect('/business-voice-and-collaboration');
    }
  }
}
